import { MetricsPanelCtrl } from 'grafana/app/plugins/sdk';
import defaultsDeep from 'lodash/defaultsDeep';
import _ from 'lodash';
import { utc } from 'moment';

import { getLocationSrv } from '@grafana/runtime';

export default class SimpleCtrl extends MetricsPanelCtrl {
  static templateUrl = 'partials/module.html';
  utc = utc;
  panelDefaults = {
    tagVarName: '',
    valueVarName: '',
    searchVarName: '',
    sensorOutVarName: '',
    tagDefinitions: [],
    drillUrlTemplate: '',
    operations: [
      { text: '=', value: '=' },
      { text: '>', value: '>' },
      { text: '<', value: '<' },
      { text: '!=', value: '<>' },
    ],
    valueFieldName: 'FloatValue',
    tagName: 'Type',
    publicApiName: 'ICB API',
    workOrders: [],
    loadingTxt: 'Loading...',
    isLoading: false,
    resultsLimit: 100,
    defaultInputVal: ''
  };

  searchText2 = '';
  selectedTag = this.panel.tagDefinitions ? this.panel.tagDefinitions[0].tag : '';
  selectedOption = this.panel.operations ? this.panel.operations[0].value : '';
  selectedOption2 = this.panel.operations ? this.panel.operations[0].value : '';
  selectedLogicOption = 'false';

  searchText = this.panel.defaultInputVal;

  timeRange: any = {};
  proxyURL: string = '';
  workOrders: any[] = [];
  loadingTxt: string = 'Loading...';
  isLoading: boolean = false;
  sensorID: string = '';
  resultsLimit: number = 100;
  defaultInputVal: string = '';
  periodsDic: any[] = [];

  /** @ngInject */
  constructor($scope, $injector, public templateSrv, private backendSrv) {
    //private contextSrv,
    super($scope, $injector);
    defaultsDeep(this.panel, this.panelDefaults);
    this.panel.workOrders = [];
    // Get results directly as DataFrames
    (this as any).dataFormat = 'series';

    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('render', this.onRender.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));
    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-snapshot-load', this.onDataSnapshotLoad.bind(this));

    this.loadProxyUrl();
  }

  onDataSnapshotLoad(snapshotData: any) {
    this.onDataReceived(snapshotData);
  }

  onDataReceived(dataList: any) {
    //no search defined
    if (this.searchText === '') {
      //clear results
      this.panel.workOrders = [];
      return;
    }

    let periodsArr: any[] = [];
    //calc periods
    periodsArr = this.calcPeriods(dataList);
    this.loadWorkOrders(periodsArr);
  }

  loadProxyUrl() {
    if (!this.proxyURL) {
      //find proxy
      this.datasourceSrv.get(this.panel.publicApiName).then((ds) => {
        this.proxyURL = `${window.location.origin}/api/datasources/${ds.id}/resources`;
      });
    }
  }

  loadWorkOrders(periodsArr: any[]) {
    //send the request
    this.panel.isLoading = true;
    const payload = {
      OrgName: this.templateSrv.replace("${__org.name}"),
      periods: periodsArr,
      sensorID: this.getSensorVariable(),
    };

    this.backendSrv
      .post(`${this.proxyURL}/publicapi/api/erp/search`, payload)
      .then((response) => {
        this.prepareWorkOrders(response);
        this.panel.isLoading = false;

        this.$scope.$apply();
      })
      .catch((error) => {
        console.log(error);
        this.panel.isLoading = false;

        this.$scope.$apply();
      });

    console.log(periodsArr);
  }

  prepareWorkOrders(response: any) {
    for (let index = 0; index < response.length; index++) {
      const el = response[index];

      const periodIdx = el.periodIndexes[0];
      const values: number[] = this.periodsDic[periodIdx].values;

      const min = Math.min.apply(Math, values);
      const max = Math.max.apply(Math, values);
      el.min = min;
      el.max = max;

      const sum = values.reduce((a, b) => a + b, 0);
      let avg = sum / values.length || 0;
      avg = Math.round(avg * 10) / 10;
      el.avg = avg;

      el.StartF = utc(el.actualStart).local().format('YYYY-MM-DD HH:mm');
      el.StopF = utc(el.actualStop).local().format('YYYY-MM-DD HH:mm');
    }

    this.panel.workOrders = response;
  }

  getSensorVariable() {
    if (this.sensorID) return this.sensorID;

    if (this.panel.scopedVars && this.panel.scopedVars['sensor']) {
      return this.panel.scopedVars['sensor'].value;
    }

    let targetValue = '';

    const v = this.getVariable('sensor');
    if (v && v.current.value !== targetValue) return v.current.value;
    return targetValue;
  }

  calcPeriods(dataList: any): any[] {
    if (
      !dataList ||
      !dataList[0] ||
      !dataList[0].columns ||
      !dataList[0].rows ||
      dataList[0].columns.length === 0 ||
      dataList[0].rows === 0
    ) {
      return [];
    }

    //sensor
    this.sensorID = '';
    for (let index = 0; index < dataList[0].columns.length; index++) {
      if (dataList[0].columns[index].text === 'SensorID') {
        this.sensorID = dataList[0].rows[dataList[0].rows.length - 1][index].toString();
        this.updateVarable(this.panel.sensorOutVarName, this.sensorID);
        break;
      }
    }

    //find value column
    let colIdx = 0;
    for (let index = 0; index < dataList[0].columns.length; index++) {
      if (dataList[0].columns[index].text === this.panel.valueFieldName) colIdx = index;
    }

    let periods: any[] = [];
    let values: number[] = [];
    let lastPeriod = { start: 0, end: 0, values: values };

    for (let index = 0; index < dataList[0].rows.length; index++) {
      const r = dataList[0].rows[index];

      //check operation
      if (this.calcOperations(r[colIdx].toString())) {
        //start/continue period
        if (lastPeriod.start === 0) {
          lastPeriod.start = r[0] * 1000000;
        }
        lastPeriod.values.push(r[colIdx]);
      } else {
        //stop/ignore period
        if (lastPeriod.start > 0) {
          //end started
          lastPeriod.end = r[0] * 1000000;
          periods.push(lastPeriod);
          lastPeriod = { start: 0, end: 0, values: [] };
        }
      }
    }

    //close last period
    if (lastPeriod.start > 0) {
      this.timeRange = this.templateSrv.timeRange;
      lastPeriod.end = this.timeRange.to._d.getTime() * 1000000;
      periods.push(lastPeriod);
    }

    //convert to arrays
    let resultArr: any[] = [];
    for (let index = 0; index < periods.length; index++) {
      const period: any = {
        start: periods[index].start,
        end: periods[index].end,
        index: index,
      };
      resultArr.push(period);
      this.periodsDic[index] = { values: periods[index].values };
    }

    return resultArr;
  }

  calcOperations(fieldValue: string) {
    if (this.searchText2 !== '' && this.selectedLogicOption !== 'false') {
      if (this.selectedLogicOption === 'AND')
        return (
          this.calcOperation(fieldValue, this.selectedOption, this.searchText) &&
          this.calcOperation(fieldValue, this.selectedOption2, this.searchText2)
        );
      else if (this.selectedLogicOption === 'OR')
        return (
          this.calcOperation(fieldValue, this.selectedOption, this.searchText) ||
          this.calcOperation(fieldValue, this.selectedOption2, this.searchText2)
        );
      else return false;
    } else {
      return this.calcOperation(fieldValue, this.selectedOption, this.searchText);
    }
  }

  calcOperation(fieldValue: string, selOperation: any, searchValue: string) {
    switch (selOperation) {
      case '=':
        return fieldValue.toString() === searchValue.toString();
      case '>':
        return parseFloat(fieldValue) > parseFloat(searchValue);
      case '<':
        return parseFloat(fieldValue) < parseFloat(searchValue);
      case '!=':
        return fieldValue.toString() !== searchValue.toString();
      default:
        return false;
    }
  }

  onInitEditMode() {
    this.addEditorTab('Options', `public/plugins/${this.pluginId}/partials/options.html`, 2);
  }

  onRender() {
    return;
  }

  onDataError(err: any) {
    console.log('onDataError', err);
  }

  updateVarable(varname: string, value: string) {
    if (!varname)
      return;
    let name = 'var-' + varname;
    getLocationSrv().update({
      partial: true,
      query: {
        [name]: value,
      },
      replace: true,
    });

    return true;
  }

  getVariable(varname: string): any {
    const v = _.find(this.templateSrv.getVariables(), (check: { name: string }) => {
      return check.name === varname;
    });
    return v;
  }

  onSubmitSearch() {
    let combined = 'true';
    let tagTxt = 'true';
    let valueTxt = 'true';

    this.panel.workOrders = [];

    if (this.selectedTag) tagTxt = this.panel.tagName + " = '" + this.selectedTag + "'";

    if (this.searchText !== '') {
      valueTxt = this.panel.valueFieldName + ' ' + this.selectedOption + ' ' + this.searchText;
      combined = this.panel.tagName + " = '" + this.selectedTag + "' AND " + valueTxt;

      if (this.searchText2 !== '' && this.selectedLogicOption !== 'false') {
        valueTxt =
          '(' +
          this.panel.valueFieldName +
          ' ' +
          this.selectedOption +
          ' ' +
          this.searchText +
          ' ' +
          this.selectedLogicOption +
          ' ' +
          this.panel.valueFieldName +
          ' ' +
          this.selectedOption2 +
          ' ' +
          this.searchText2 +
          ')';
        combined = this.panel.tagName + " = '" + this.selectedTag + "' AND " + valueTxt;
      }
    }

    this.updateVarable(this.panel.tagVarName, tagTxt);
    this.updateVarable(this.panel.valueVarName, valueTxt);
    this.updateVarable(this.panel.searchVarName, combined);

    this.refresh();
  }

  onAddDefinition() {
    this.panel.tagDefinitions.push({ text: 'Text', tag: 'Tag' });
  }
  onRemoveDefinition(idx: number) {
    this.panel.tagDefinitions.splice(idx, 1);
  }
  onGeneratePeriods() {
    console.log(this.panel.generatePeriods);
  }
}

export { SimpleCtrl as PanelCtrl };
